const classification = {
  grapes: [
    'Baco Noir',
    'Barbera',
    'Cabernet Franc',
    'Cabernet Sauvignon',
    'Catawba',
    'Cayuga White',
    'Chambourcin',
    'Chancellor',
    'Chardonel',
    'Chardonnay',
    'Chelois',
    'Chenin Blanc',
    'Concord',
    'Delaware',
    'Frontenac',
    'Gewürztraminer',
    'Malbec',
    'Maréchal Fochr',
    'Merlot',
    'Norton',
    'Pinot Blanc',
    'Pinot Gris',
    'Pinot Noir',
    'Riesling',
    'Sangiovese',
    'Sauvignon Blanc',
    'Seyval Blanc',
    'Syrah',
    'Sémillon',
    'Traminette',
    'Vidal Blanc',
    'Vignoles',
    'Zinfandel',
  ],
};

export default classification;
